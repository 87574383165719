<script setup lang="ts">
const title = 'Terms of Use - Play Fair with EasyFun.gg'
const description = 'Familiarize yourself with EasyFun.gg’s terms. Enjoy fair play and understand your rights as you game online.'
useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})
</script>

<template>
  <div class="view-content w-full flex flex-col pt-10">
    <h2 class="mrd-title" un-before="mrd-title-i">
      Introduction
    </h2>
    <p class="mrd-content">
      This section introduces EasyFun, outlining its purpose as a platform for users to play mobile games online. It emphasizes the platform's commitment to providing a diverse and engaging gaming experience, accessible from various devices without the need for downloads. The introduction sets the stage for the terms and conditions that follow, establishing the legal framework within which users access and interact with the site's content and services.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Acceptance of Terms
    </h2>
    <p class="mrd-content">
      By accessing and using our platform, users agree to adhere to the Terms of Use outlined. This agreement is binding from the moment of access and includes all aspects of the service provided. Users are advised to read these terms carefully before proceeding, as continued use of the website constitutes acceptance of any and all terms, including future amendments.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      User Accounts and Security
    </h2>
    <p class="mrd-content">
      When creating an account on our website, ensure the information you provide is accurate and up to date. It's crucial to keep your account details secure, including your password. If you suspect your account has been accessed without your permission, please notify us immediately. This will help maintain the security of your personal information and enhance your gaming experience on our platform.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Content Use and Intellectual Property
    </h2>
    <p class="mrd-content">
      Respect the intellectual property rights of the games and content available on our website. You are permitted to use the content solely for personal, non-commercial purposes. Reproducing, distributing, or modifying the content without authorization is prohibited. Be mindful of the copyrights and trademarks associated with the games and materials provided, as unauthorized use may result in legal consequences.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      User Conduct
    </h2>
    <p class="mrd-content">
      When using our platform to play mobile games online, users are expected to conduct themselves in a respectful and responsible manner. This includes refraining from engaging in any activities that may disrupt the functionality of the website or interfere with other users' experiences. Additionally, users must comply with all applicable laws and regulations governing online conduct and content. Any behavior deemed inappropriate, such as harassment, cheating, or exploiting glitches, will result in immediate termination of the user's account.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Modifications to Service and Terms
    </h2>
    <p class="mrd-content">
      We reserve the right to modify, suspend, or terminate any aspect of our service, including games, features, or terms of use, at any time and without prior notice. Users acknowledge and agree that we shall not be liable to them or any third party for any modifications, suspension, or termination of the service. Any changes to the terms of use will be effective immediately upon posting on the website. It is the responsibility of users to review the terms periodically for updates. Continued use of the service after any changes indicates acceptance of the modified terms.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Termination
    </h2>
    <p class="mrd-content">
      We reserve the right to terminate or suspend access to your account or the service at our discretion, without prior notice or liability, for any reason, including but not limited to a breach of the Terms. Upon termination, your right to use the service will cease immediately. If your account is terminated due to a violation of the Terms, you will not be entitled to any refunds of fees, if applicable, and any outstanding balances will become due immediately.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Disclaimers and Limitations of Liability
    </h2>
    <p class="mrd-content">
      EasyFun and its content are provided on an "as is" and "as available" basis, without any warranties, express or implied. We do not guarantee that our service will be uninterrupted, secure, or error-free. Users agree to use the service at their own risk and understand that we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in any way connected with the use of our website or any content on it.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Contact Information
    </h2>
    <p class="mrd-content">
      If you have any questions or concerns regarding these Terms of Use, please <b class="mrd-b">contact us at contact@easyfun.gg</b>. You may also reach out to us through the contact information on our website. We are committed to addressing any inquiries promptly and providing assistance as needed.
    </p>
  </div>
</template>
