<script setup lang="ts">
const route = useRoute()
const url = useRequestURL()
const { public: { NUXT_APP_CDN_URL, PUBLIC_SITE_URL } } = useRuntimeConfig()

const VEST_DOMAIN_REG = /learnmathsci|higheredunity|learningparity/

useSeoMeta({
  ogImage: `${NUXT_APP_CDN_URL}og.png`,
})
</script>

<template>
  <Head>
    <Meta name="theme-color" content="#1a1a1a" />
    <Link rel="canonical" :href="`${PUBLIC_SITE_URL}${route.path}`" />
    <Meta v-if="VEST_DOMAIN_REG.test(url.hostname)" name="robots" content="noindex, nofollow" />
  </Head>
  <NuxtPwaAssets />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
