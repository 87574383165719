import revive_payload_client_cgrgyupcDJ from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2vj8PHIFdr from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xwCOnbAjmr from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wM4v7eclhD from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_29CNQYrWan from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_NlNnGQ0vMh from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/workspace/easyfun_web_UkvO/.nuxt/components.plugin.mjs";
import prefetch_client_7pfF0cskPz from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_RNTyf3qN7X from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._q7qa6dtlxmfee26who4tudprti/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/root/workspace/easyfun_web_UkvO/.nuxt/sentry-client-config.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/root/workspace/easyfun_web_UkvO/.nuxt/pwa-icons-plugin.ts";
import pwa_client_rL4IyPdlZ0 from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_rollup@4.17.2_vite@5.2.11_@types+node@20.12.10_lightningcss@1.24.1_sass@_2rjxn6usbwgyzfr3azc2il7plm/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_LK7ijhW814 from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/root/workspace/easyfun_web_UkvO/.nuxt/unocss.mjs";
import plugin_xuy7MCCzJQ from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.17.2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_6tOIGm2HdW from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_6hDmqiAto0 from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@_i4f2jrlxspkyyfqtrsmmywd4ky/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import google_login_HL7L2sPg2R from "/root/workspace/easyfun_web_UkvO/plugins/google-login.ts";
import img_lazy_PBK1dGp15P from "/root/workspace/easyfun_web_UkvO/plugins/img-lazy.ts";
import vue_draggable_resizable_client_WHb8q3t7EE from "/root/workspace/easyfun_web_UkvO/plugins/vue-draggable-resizable.client.ts";
import vue3_marquee_client_ywbRsmFvmE from "/root/workspace/easyfun_web_UkvO/plugins/vue3-marquee.client.ts";
export default [
  revive_payload_client_cgrgyupcDJ,
  unhead_2vj8PHIFdr,
  router_xwCOnbAjmr,
  payload_client_wM4v7eclhD,
  check_outdated_build_client_29CNQYrWan,
  plugin_vue3_NlNnGQ0vMh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7pfF0cskPz,
  sentry_client_RNTyf3qN7X,
  sentry_client_config_o34nk2sJbg,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_rL4IyPdlZ0,
  i18n_LK7ijhW814,
  unocss_MzCDxu9LMj,
  plugin_xuy7MCCzJQ,
  chunk_reload_client_6tOIGm2HdW,
  plugin_6hDmqiAto0,
  google_login_HL7L2sPg2R,
  img_lazy_PBK1dGp15P,
  vue_draggable_resizable_client_WHb8q3t7EE,
  vue3_marquee_client_ywbRsmFvmE
]