import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAdsBlockTips, LazyAnnouncement, LazyBuildOnly, LazyOptionsList, LazyTooltip, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["AdsBlockTips", LazyAdsBlockTips],
["Announcement", LazyAnnouncement],
["BuildOnly", LazyBuildOnly],
["OptionsList", LazyOptionsList],
["Tooltip", LazyTooltip],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
