<script setup lang="ts">
import { NuxtLink } from '#components'
import IMG_DISCORD_ICON from '@/assets/images/discord-icon.png?url'
import { toTitleCase } from '@/utils'
import type { ITagBlockProps } from '@/types/components'

interface Props extends Partial<Omit<ITagBlockProps, 'id'>> {
  showText?: boolean
  to?: string
  active?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showText: true,
})

const { t } = useI18n()

const tagName = computed(() => props.labelKey ? t(props.labelKey) : toTitleCase(props.categoryName || ''))

const tagLink = computed((() => {
  if (props.to !== undefined)
    return props.to

  else if (props.categoryName)
    return `/tags/${props.categoryName}.html`
}))
</script>

<template>
  <component :is="tagLink ? NuxtLink : 'div'" class="ld-button gap-0 rd-2 px-4 py-2.25 ld-button-black" :class="active && 'bg-gray-3 b-gray-3'" :to="tagLink" target="_blank" no-rel>
    <img class="tag-img size-5 md:size-6" :src="icon || IMG_DISCORD_ICON" alt="">
    <span v-if="showText" class="mis-2 break-all text-3.5 font-600">{{ tagName }}</span>
  </component>
</template>

<style lang="scss" scoped>
</style>
