<script setup lang="ts">
import SVG_AD_BLOCK_TIPS_ICON from '@/assets/svg/ad-block-tips-icon.svg'

const isRender = ref(false)
onMounted(() => {
  isRender.value = !window.EASYFUN_ADS_CAN_RUN && process.env.NODE_ENV !== 'development'
})
</script>

<template>
  <!-- block tips -->
  <div v-if="isRender" class="flex-center flex-col">
    <SVG_AD_BLOCK_TIPS_ICON />
    <NuxtLink to="https://docs.google.com/document/d/1I0XvarBMceDu_g9p-9whjW67E2cOpoinVq76RYEhkXw/edit" target="_blank">
      <span class="mt-2 text-3.5 c-#fff c-op-30 underline hover:c-op-100">{{ $t('ads.blockTips') }}</span>
    </NuxtLink>
  </div>
</template>
