import matcherLang, { parserAcceptLang, serverRenderLang } from '@/utils/detect-lang'

export default defineNuxtRouteMiddleware((to) => {
  if (!to?.matched?.length)
    return

  // render -> localStorage > cookie > header accept > navigator.language
  const { $i18n } = useNuxtApp()
  const cookieLang = useCookie(import.meta.env.VITE_LANG_COOKIE_KEY, { maxAge: Number(import.meta.env.VITE_LANG_COOKIE_MAX_AGE) })
  const localStorageLocale = useLocalStorage(import.meta.env.VITE_LANG_LOCAL_STORAGE_KEY, '')

  if (process.server) {
    // server render lang
    const formatHeaderLang = parserAcceptLang(useRequestHeader('accept-language') || '')
    const lang = serverRenderLang(cookieLang.value, matcherLang(formatHeaderLang))

    $i18n.locale.value = lang
    cookieLang.value = lang
  }
  else {
    // client set lang
    $i18n?.setLocale(localStorageLocale.value || cookieLang.value || matcherLang(navigator.language) || 'en_US')
  }
})
