<script setup lang="ts">
const title = 'Cookie Policy - Enhanced Gaming at EasyFun.gg'
const description = 'Understand how EasyFun.gg uses cookies to improve your gaming experience and provide valuable insights.'
useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})
</script>

<template>
  <div class="view-content w-full flex flex-col pt-10">
    <h2 class="mrd-title" un-before="mrd-title-i">
      Welcome to EasyFun.gg
    </h2>
    <p class="mrd-content">
      This Cookies Policy applies to [EasyFun] and our website available at [www.easyfun.gg] (collectively,  the “Services”). When you use the Services, we may use cookies, log files, pixel tags, web beacons, scripts, eTags device IDs and similar files or technologies (“Cookies”) to collect and store information about your device and use of the Services. You can find out more about Cookies and how to control them below.
    </p>
    <p class="mrd-content">
      For the purposes of this Cookies Policy, “we”, “us” and “our” means [FunEase Network Technology Limited], a company incorporated in HongKong with the registered address at 6/F Manulife Place, 348 Kwun Tong Road, Kowloon, Hong Kong. For the purpose of any personal data we collect through cookies, we are the data controller.
    </p>

    <p class="mrd-content">
      If you do not accept the use of these Cookies, please disable them using the instructions below, by changing your settings so that Cookies from us cannot be placed on your device.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      1. What is a Cookie?
    </h2>
    <p class="mrd-content">
      Cookies are text files containing small amounts of information which are downloaded to your browser or device when you visit a certain web address. Cookies are then sent back to the originating web address on each subsequent visit, or to another web address that recognises that Cookie. Cookies are widely used in order to make websites work, or to work more efficiently, as well as to provide information about how the website is used.
    </p>

    <p class="mrd-content">
      Cookies do lots of different jobs, like letting you navigate between pages efficiently, remembering your preferences, and generally improving the user experience. Cookies may tell us, for example, whether you have used the website before or whether you are a new user.
    </p>

    <p class="mrd-content">
      Cookies can remain on your device for different periods of time. Some Cookies are 'session Cookies’, meaning that they exist only while your browser or the application is open. These are deleted automatically once you close your browser or application. Other Cookies are 'permanent Cookies’, meaning that they survive after your browser or application is closed. They can be used by the Services to recognise your device when you open and use the Services again.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      2. What types of Cookies do we use and how do we use them?
    </h2>
    <p class="mrd-content">
      There are five main types of cookies:
    </p>
    <ul class="mt-4 mrd-ul">
      <li>
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Strictly necessary cookies:</strong> <p class="pis-5 mrd-content !mt-1">
          These cookies are essential to enable you to login, navigate around and use the features of our websites or services, or to provide a service requested by you (such as your username). We do not need to obtain your consent in order to use these cookies. These cookies can be used for security reasons – for example to detect violation of our policies, identify and prevent fraud, fulfill your requests and to support other security features.
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Functionality cookies:</strong> <p class="pis-5 mrd-content !mt-1">
          These cookies allow us to remember choices you make (such as your language) and provide enhanced and personalized features. The information these cookies collect remains anonymous and they cannot track your browsing activity.
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Performance cookies:</strong> <p class="pis-5 mrd-content !mt-1">
          These cookies collect information about your online activity, including behavioral data and content engagement metrics. These cookies are used for analytics, research and to perform statistics (based on aggregated information).
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Targeting cookies or advertising cookies:</strong> <p class="pis-5 mrd-content !mt-1">
          These cookies are used to deliver tailored offers and advertisements to you, based on your derived interests. They can also be used to cap the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by our advertisers (for example advertising networks) and provide them insights about the people who see and interact with their ads, visit their websites or use their app.
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Social media cookies:</strong> <p class="pis-5 mrd-content !mt-1">
          Our website and services may include social media features, such as the Facebook “Like” or “Share” buttons. These features are either hosted by a third party or hosted directly through our services. Your interactions with these features are governed by the privacy statement of the company providing these features.<br>Below you can find out more about the cookies we use. Please note that the table also includes other types of Tracking Technologies, such as third party SDKs.
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Google Analytic: &nbsp;&nbsp; - Third party performance cookie -</strong> <p class="pis-5 mrd-content !mt-1">
          This cookie is used to collect information about how visitors use our website and services. We use the information to compile reports and to help us improve our services. The cookies collect information in an anonymous form, including the number of visitors to our website and services, where visitors have come to our website and the pages they visited. <NuxtLink class="color-green-5" target="_blank" to="https://policies.google.com/privacy?hl=en&fg=1">
            Privacy Policy
          </NuxtLink>
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Facebook: &nbsp;&nbsp; - Third party Social media and advertising cookie -</strong> <p class="pis-5 mrd-content !mt-1">
          To enable us to optimize and serve advertising on Facebook and other third party sites based on a) your past visits to our website and b) your interaction with our adverts and content on Facebook, advertisements are served after you have visited our site using non-personally identifiable information.<NuxtLink class="color-green-5" target="_blank" to="https://www.facebook.com/about/privacy/">
            Privacy Policy
          </NuxtLink>
        </p>
      </li>
      <li class="mt-2.5">
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Other cookies: &nbsp;&nbsp; – First and Third party strictly necessary cookies –</strong> <p class="pis-5 mrd-content !mt-1">
          Other unlisted cookies might be in use on internal sections of the website or our services.
        </p>
      </li>
    </ul>

    <h2 class="mrd-title" un-before="mrd-title-i">
      3. How to control or delete Cookies
    </h2>
    <p class="mrd-content">
      You have the right to choose whether or not to accept Cookies. To opt-in to or out of Cookies, please click on our banner which will pop-up on your first visit to 【www.easyfun.gg】. However, please note that if you choose to refuse Cookies you may not be able to use the full functionality of 【EasyFun】.
    </p>
    <p class="mrd-content">
      You can update your preferences at any time - most devices (in the case of mobile applications) and browsers (in the case of web apps and pages) allow you to change your Cookie settings. These settings will typically be found in the “options” or “preferences” menu of your browser. The following links provide information about how to manage cookies on popular browsers, otherwise you should use the “Help” option in your browser for more details.
    </p>

    <ul class="mt-4 mrd-ul">
      <li>
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;<NuxtLink to="https://support.google.com/accounts/answer/61416?co=GENIE.Platform=Desktop&hl=en" target="_blank">
          Google Chrome
        </NuxtLink></strong>
      </li>
      <li>
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;<NuxtLink to="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank">
          Microsoft Edge
        </NuxtLink></strong>
      </li>
      <li>
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;<NuxtLink to="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">
          Mozilla Firefox
        </NuxtLink></strong>
      </li>
      <li>
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;<NuxtLink to="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">
          Microsoft Internet Explorer
        </NuxtLink></strong>
      </li>
      <li>
        <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;<NuxtLink to="https://support.apple.com/en-gb/safari" target="_blank">
          Apple Safari
        </NuxtLink></strong>
      </li>
    </ul>
    <p class="mrd-content">
      To find information relating to other browsers, visit the browser developer's website.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      4. Changes to this Cookies Policy
    </h2>
    <p class="mrd-content">
      We will update this Cookies Policy to reflect changes in our practices and services. When we post changes to this Cookies Policy, we will revise the "Last Updated" date at the top of this Cookies Policy. If we make any material changes in the way we collect, use, and/or share information held in Cookies, we will notify you by prominently posting notice of the changes when you log in to or use the Services. We recommend that you check this page from time to time to inform yourself of any changes in this Cookies Policy or any of our other policies.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      5. Cookies that have been set in the past
    </h2>
    <p class="mrd-content">
      If you have disabled one or more Cookies, we may still use information collected from Cookies prior to your disabled preference being set, however, we will stop using the disabled Cookie to collect any further information.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      6. Contact us
    </h2>
    <p class="mrd-content">
      If you have any questions or comments about this policy, or privacy matters generally, please contact us via email at <b class="mrd-b">contact@easyfun.gg</b>.
    </p>
  </div>
</template>
