import { default as aboutsXDj6ro9XtMMeta } from "/root/workspace/easyfun_web_UkvO/pages/abouts.vue?macro=true";
import { default as cookie_45policyU0doBS5CLBMeta } from "/root/workspace/easyfun_web_UkvO/pages/cookie-policy.vue?macro=true";
import { default as decode_45watermark_45upupupupUJ92iTFzovMeta } from "/root/workspace/easyfun_web_UkvO/pages/decode-watermark-upupupup.vue?macro=true";
import { default as faqubV35Xr0OsMeta } from "/root/workspace/easyfun_web_UkvO/pages/faq.vue?macro=true";
import { default as game_45detailMJ7TKYbLQdMeta } from "/root/workspace/easyfun_web_UkvO/pages/game-detail.vue?macro=true";
import { default as indexyvRn02l6AzMeta } from "/root/workspace/easyfun_web_UkvO/pages/index.vue?macro=true";
import { default as privacy_45policyliF3KBZNtkMeta } from "/root/workspace/easyfun_web_UkvO/pages/privacy-policy.vue?macro=true";
import { default as searchAGf6rrFEbMMeta } from "/root/workspace/easyfun_web_UkvO/pages/search.vue?macro=true";
import { default as tagsHIziQ4gYAnMeta } from "/root/workspace/easyfun_web_UkvO/pages/tags.vue?macro=true";
import { default as terms_45of_45use0XnjQxonTzMeta } from "/root/workspace/easyfun_web_UkvO/pages/terms-of-use.vue?macro=true";
import { default as testXL3y6uEAKuMeta } from "/root/workspace/easyfun_web_UkvO/pages/test.vue?macro=true";
import { default as tagsKl5XNzsRKZMeta } from "~/pages/tags.vue?macro=true";
import { default as game_45detailr9NamQbKl2Meta } from "~/pages/game-detail.vue?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuysMeta } from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuys } from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: aboutsXDj6ro9XtMMeta?.name ?? "abouts",
    path: aboutsXDj6ro9XtMMeta?.path ?? "/abouts.html",
    meta: aboutsXDj6ro9XtMMeta || {},
    alias: aboutsXDj6ro9XtMMeta?.alias || [],
    redirect: aboutsXDj6ro9XtMMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/abouts.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyU0doBS5CLBMeta?.name ?? "cookie-policy",
    path: cookie_45policyU0doBS5CLBMeta?.path ?? "/cookie-policy.html",
    meta: cookie_45policyU0doBS5CLBMeta || {},
    alias: cookie_45policyU0doBS5CLBMeta?.alias || [],
    redirect: cookie_45policyU0doBS5CLBMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: decode_45watermark_45upupupupUJ92iTFzovMeta?.name ?? "decode-watermark-upupupup",
    path: decode_45watermark_45upupupupUJ92iTFzovMeta?.path ?? "/decode-watermark-upupupup.html",
    meta: decode_45watermark_45upupupupUJ92iTFzovMeta || {},
    alias: decode_45watermark_45upupupupUJ92iTFzovMeta?.alias || [],
    redirect: decode_45watermark_45upupupupUJ92iTFzovMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/decode-watermark-upupupup.vue").then(m => m.default || m)
  },
  {
    name: faqubV35Xr0OsMeta?.name ?? "faq",
    path: faqubV35Xr0OsMeta?.path ?? "/faq.html",
    meta: faqubV35Xr0OsMeta || {},
    alias: faqubV35Xr0OsMeta?.alias || [],
    redirect: faqubV35Xr0OsMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: game_45detailMJ7TKYbLQdMeta?.name ?? "game-detail",
    path: game_45detailMJ7TKYbLQdMeta?.path ?? "/game-detail",
    meta: game_45detailMJ7TKYbLQdMeta || {},
    alias: game_45detailMJ7TKYbLQdMeta?.alias || [],
    redirect: game_45detailMJ7TKYbLQdMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/game-detail.vue").then(m => m.default || m)
  },
  {
    name: indexyvRn02l6AzMeta?.name ?? "index",
    path: indexyvRn02l6AzMeta?.path ?? "/",
    meta: indexyvRn02l6AzMeta || {},
    alias: indexyvRn02l6AzMeta?.alias || [],
    redirect: indexyvRn02l6AzMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyliF3KBZNtkMeta?.name ?? "privacy-policy",
    path: privacy_45policyliF3KBZNtkMeta?.path ?? "/privacy-policy.html",
    meta: privacy_45policyliF3KBZNtkMeta || {},
    alias: privacy_45policyliF3KBZNtkMeta?.alias || [],
    redirect: privacy_45policyliF3KBZNtkMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: searchAGf6rrFEbMMeta?.name ?? "search",
    path: searchAGf6rrFEbMMeta?.path ?? "/search.html",
    meta: searchAGf6rrFEbMMeta || {},
    alias: searchAGf6rrFEbMMeta?.alias || [],
    redirect: searchAGf6rrFEbMMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/search.vue").then(m => m.default || m)
  },
  {
    name: tagsHIziQ4gYAnMeta?.name ?? "tags",
    path: tagsHIziQ4gYAnMeta?.path ?? "/tags",
    meta: tagsHIziQ4gYAnMeta || {},
    alias: tagsHIziQ4gYAnMeta?.alias || [],
    redirect: tagsHIziQ4gYAnMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45use0XnjQxonTzMeta?.name ?? "terms-of-use",
    path: terms_45of_45use0XnjQxonTzMeta?.path ?? "/terms-of-use.html",
    meta: terms_45of_45use0XnjQxonTzMeta || {},
    alias: terms_45of_45use0XnjQxonTzMeta?.alias || [],
    redirect: terms_45of_45use0XnjQxonTzMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: testXL3y6uEAKuMeta?.name ?? "test",
    path: testXL3y6uEAKuMeta?.path ?? "/test.html",
    meta: testXL3y6uEAKuMeta || {},
    alias: testXL3y6uEAKuMeta?.alias || [],
    redirect: testXL3y6uEAKuMeta?.redirect,
    component: () => import("/root/workspace/easyfun_web_UkvO/pages/test.vue").then(m => m.default || m)
  },
  {
    name: tagsKl5XNzsRKZMeta?.name ?? "tags",
    path: tagsKl5XNzsRKZMeta?.path ?? "/tags/:name.html",
    meta: tagsKl5XNzsRKZMeta || {},
    alias: tagsKl5XNzsRKZMeta?.alias || [],
    redirect: tagsKl5XNzsRKZMeta?.redirect,
    component: () => import("~/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: tagsKl5XNzsRKZMeta?.name ?? "tags-all",
    path: tagsKl5XNzsRKZMeta?.path ?? "/tags.html",
    meta: tagsKl5XNzsRKZMeta || {},
    alias: tagsKl5XNzsRKZMeta?.alias || [],
    redirect: tagsKl5XNzsRKZMeta?.redirect,
    component: () => import("~/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: game_45detailr9NamQbKl2Meta?.name ?? "cloud-game",
    path: game_45detailr9NamQbKl2Meta?.path ?? "/games/:name.html",
    meta: game_45detailr9NamQbKl2Meta || {},
    alias: game_45detailr9NamQbKl2Meta?.alias || [],
    redirect: game_45detailr9NamQbKl2Meta?.redirect,
    component: () => import("~/pages/game-detail.vue").then(m => m.default || m)
  },
  {
    name: game_45detailr9NamQbKl2Meta?.name ?? "web-game",
    path: game_45detailr9NamQbKl2Meta?.path ?? "/webgames/:name.html",
    meta: game_45detailr9NamQbKl2Meta || {},
    alias: game_45detailr9NamQbKl2Meta?.alias || [],
    redirect: game_45detailr9NamQbKl2Meta?.redirect,
    component: () => import("~/pages/game-detail.vue").then(m => m.default || m)
  },
  {
    name: game_45detailr9NamQbKl2Meta?.name ?? "html5-games",
    path: game_45detailr9NamQbKl2Meta?.path ?? "/html5-games/:name.html",
    meta: game_45detailr9NamQbKl2Meta || {},
    alias: game_45detailr9NamQbKl2Meta?.alias || [],
    redirect: game_45detailr9NamQbKl2Meta?.redirect,
    component: () => import("~/pages/game-detail.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuysMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuysMeta?.path ?? "/webgames/:pathMatch(.*)",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuysMeta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuysMeta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuysMeta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612SZyJJdCuys
  }
]