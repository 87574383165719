// 根据 manifest.webmanifest.ts 里 protocol_handlers 定下的协议规则，用中间件去跳转到对应页面
// 规则：web+easyfun://games:游戏seo
export default defineNuxtRouteMiddleware((to) => {
  const { path, query } = to
  if (path.includes('protocol')) {
    const matchKey = (query.params as string).match(/web\+easyfun:\/\/(.+)\//)?.[1]
    // 去游戏详情
    if (matchKey?.startsWith('games:')) {
      const gameSeo = matchKey.replace('games:', '')
      return navigateTo({ path: `/games/${gameSeo}.html` })
    }
  }
})
