<script setup lang="ts">
const { t } = useI18n()
const discordUrl = import.meta.env.VITE_DISCORD_URL

const title = computed(() => t('title.faq', 'Find Answers Quickly - EasyFun.gg FAQ'))
const description = computed(() => t('description.faq', 'Any questions about playing your favorite mobile games in your browser with EasyFun.gg, please check the FAQ.'))

useCacheRoute(600)

useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})

const qaList = computed(() => Array.from({ length: 13 }).map((_, i) => {
  return { q: t(`faq.${i + 1}Q`), a: t(`faq.${i + 1}A`, { discordUrl }).replace(/\\.{0,1}n/g, '\n').replace('$http', 'http') }
}))
</script>

<template>
  <div class="view-content flex flex-col pt-20">
    <CommonFaqList :qa-list="qaList" />
  </div>
</template>
