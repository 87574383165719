<script setup lang="ts">
import SVG_NONE_FONT_LOGO from '~/assets/svg/none-font-logo.svg'
import SVG_GAME_ICON from '~/assets/svg/about-us/game.svg'
import SVG_STAR_ICON from '~/assets/svg/about-us/star.svg'
import SVG_WHT_1 from '~/assets/svg/about-us/why-1.svg'
import SVG_WHT_2 from '~/assets/svg/about-us/why-2.svg'
import SVG_WHT_3 from '~/assets/svg/about-us/why-3.svg'
import SVG_WHT_4 from '~/assets/svg/about-us/why-4.svg'
import SVG_TICK_ICON from '~/assets/svg/about-us/tick.svg'
import SVG_EARTH_ICON from '~/assets/svg/about-us/earth.svg'

const { t } = useI18n()

const title = computed(() => t('title.abouts', 'About EasyFun.gg - Simplifies and accelerates access to mobile gaming.'))
const description = computed(() => t('description.abouts', 'EasyFun.gg brings mobile games to your browser, making access to mobile gaming more convenient.'))

useCacheRoute(600)

useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})
</script>

<template>
  <div class="view-content w-full pt-10 md:pt-20">
    <section class="w-full flex gap-10 lt-md:flex-col-reverse">
      <div class="max-w-147 w-full flex-auto flex-col">
        <div class="flex items-center">
          <SVG_GAME_ICON class="mie-2" />
          <span class="text-6 c-#fff c-op-90 font-[Bebas_Neue]">{{ $t('abouts.title') }}</span>
        </div>
        <p class="mrd-content">
          {{ $t('abouts.intro') }}
        </p>
        <span class="mt-10 block text-4 c-#fff c-op-90 font-600 md:text-3.5">{{ $t('abouts.whatIsEasyfunTitle') }}</span>
        <p class="mrd-content">
          {{ $t('abouts.whatIsEasyfunIntro') }}
        </p>
      </div>

      <div class="flex-center flex-1">
        <img width="290" height="290" class="size-50 md:size-fit" src="@/assets/images/3d-logo.png" alt="">
        <!-- <SVG_NONE_FONT_LOGO width="290" height="290" class="size-50 md:size-fit" /> -->
      </div>
    </section>

    <section class="mt-10 md:mt-20">
      <div class="flex flex-col">
        <div class="flex items-center">
          <SVG_STAR_ICON class="mie-2" />
          <span class="text-6 c-#fff c-op-90 font-[Bebas_Neue]">{{ $t('abouts.whyChooseTitle') }}</span>
        </div>

        <div class="grid mt-6 w-full gap-6 md:grid-cols-2">
          <div class="flex flex-col rd-2 bg-gray-2 p-5 md:p-10">
            <SVG_WHT_1 />
            <span class="mt-6 text-4 c-#fff c-op-90 font-600 md:text-3.5">{{ $t('abouts.whyChooseBlock1Title') }}</span>
            <p class="mrd-content mt-3! text-start!">
              {{ $t('abouts.whyChooseBlock1Content') }}
            </p>
          </div>
          <div class="flex flex-col rd-2 bg-gray-2 p-5 md:p-10">
            <SVG_WHT_2 />
            <span class="mt-6 text-4 c-#fff c-op-90 font-600 md:text-3.5">{{ $t('abouts.whyChooseBlock2Title') }}</span>
            <p class="mrd-content mt-3! text-start!">
              {{ $t('abouts.whyChooseBlock2Content') }}
            </p>
          </div>
          <div class="flex flex-col rd-2 bg-gray-2 p-5 md:p-10">
            <SVG_WHT_3 />
            <span class="mt-6 text-4 c-#fff c-op-90 font-600 md:text-3.5">{{ $t('abouts.whyChooseBlock3Title') }}</span>
            <p class="mrd-content mt-3! text-start!">
              {{ $t('abouts.whyChooseBlock3Content') }}
            </p>
          </div>
          <div class="flex flex-col rd-2 bg-gray-2 p-5 md:p-10">
            <SVG_WHT_4 />
            <span class="mt-6 text-4 c-#fff c-op-90 font-600 md:text-3.5">{{ $t('abouts.whyChooseBlock4Title') }}</span>
            <p class="mrd-content mt-3! text-start!">
              {{ $t('abouts.whyChooseBlock4Content') }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="grid mt-10 gap-10" md="grid-cols-2 mt-20 gap-6">
      <div class="flex flex-1 flex-col">
        <div class="flex items-center">
          <SVG_TICK_ICON class="mie-2" />
          <span class="text-6 c-#fff c-op-90 lh-6 font-[Bebas_Neue]">{{ $t('abouts.safeTitle') }}</span>
        </div>
        <p class="mrd-content">
          {{ $t('abouts.safeContent') }}
        </p>
      </div>
      <div class="flex flex-1 flex-col">
        <div class="flex items-center">
          <SVG_EARTH_ICON class="mie-2" />
          <span class="text-6 c-#fff c-op-90 lh-6 font-[Bebas_Neue]">{{ $t('abouts.joinTitle') }}</span>
        </div>
        <p class="mrd-content">
          {{ $t('abouts.joinContent') }}
        </p>
      </div>
    </section>
  </div>
</template>
