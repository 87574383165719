import { LocaleMatcher } from '@phensley/locale-matcher'
import langParser from 'accept-language-parser'
import { locales } from '@/contants/locales'

const matcher = new LocaleMatcher(locales.map(v => v.code))

export default function matcherLang(lang: string) {
  return matcher.match(lang).locale.id
}

export function serverRenderLang(cookieLang: string | null | undefined, headerLang: string) {
  return cookieLang || headerLang || 'en_US'
}

export function parserAcceptLang(acceptLang: string) {
  const headerLangArr = langParser.parse(acceptLang)
  const { code, region = '' } = headerLangArr?.length > 0 ? headerLangArr[0] : { code: 'en_US', region: undefined }
  return `${code}${region ? `_${region}` : ''}`
}
