export function loadJsFile(src: string, attrs?: Array<{ name: string, value: string }>) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    attrs?.map(v => script.setAttribute(v.name, v.value))
    script.src = src
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export function toTitleCase(phrase: string) {
  return phrase
    .replaceAll('-', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
};

export function sleep(ms: number) {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(true)
      clearTimeout(timer)
    }, ms)
  })
}

export function mobileCheck() {
  const { isMobile } = useDevice()
  return isMobile
}
