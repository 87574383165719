<script setup lang="ts">
import SVG_FAQ_ICON from '~/assets/svg/home-desc/faq.svg?url'

interface Props {
  qaList: Record<'q' | 'a', string>[]
}

const props = defineProps<Props>()

const activeList = ref<Record<'active', boolean>[]>([])
watchEffect(() => {
  activeList.value = props.qaList.map(() => ({ active: false }))
})
</script>

<template>
  <div class="flex items-center">
    <img :src="SVG_FAQ_ICON" class="mie-2 size-6 -mt-1" alt="faq-icon">
    <span class="text-6 c-#fff c-op-90 font-[Bebas_Neue]">{{ $t('faq.title') }}</span>
  </div>
  <section class="mt-4 flex flex-col gap-4">
    <div v-for="(row, i) in activeList" :key="i" class="w-full rd-3 bg-gray-2" lt-md="p-5" md="p-6" @click="row.active = !row.active">
      <div class="flex cursor-pointer items-center justify-between">
        <h3 class="m-0 text-3.5 c-#fff c-op-90 font-600 lh-5" :class="!row.active && 'line-clamp-2'">
          {{ qaList[i].q }}
        </h3>
        <svg class="mis-1 flex-shrink-0 transition-all-300" :class="{ '-rotate-180': row.active }" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 7L8 9L12 7" stroke="#CCFF00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <LdFold :visible="row.active">
        <div class="mt-4 ws-pre-line rd-2 bg-gray-3 px-4 py-3 text-3.5 c-#fff c-op-50 lh-5" v-html="qaList[i].a" />
      </LdFold>
    </div>
  </section>
</template>
