import validate from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45protocol_45relay_45global from "/root/workspace/easyfun_web_UkvO/middleware/01.protocol-relay.global.ts";
import _02_45i18n_45global from "/root/workspace/easyfun_web_UkvO/middleware/02.i18n.global.ts";
import _03_45route_45query_45global from "/root/workspace/easyfun_web_UkvO/middleware/03.route-query.global.ts";
import manifest_45route_45rule from "/root/workspace/easyfun_web_UkvO/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_d3la3t4d264o47kjbwoe7aho6e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45protocol_45relay_45global,
  _02_45i18n_45global,
  _03_45route_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}