let utmId: string | null = null
let prevLink: string | null = null

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.prerender && !process.server) {
    if (prevLink !== to.path) {
      prevLink = to.path
      if (from.query.utm_id)
        utmId = from.query.utm_id as string

      const query = { ...to.query }
      if (utmId)
        query.utm_id = utmId

      return navigateTo({ path: to.path, query })
    }
  }
})
