<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

useHead({
  title: `Oops! ${props?.error?.statusCode}`,
})

if (props?.error?.statusCode !== 404)
  console.warn(props.error)

function tapRefreshPage() {
  window.location.reload()
}
</script>

<template>
  <ClientOnly>
    <div class="h-100vh w-full flex-center flex-col p-10 lg:flex-row" lt-lg:landscape="flex-row" gap="10 lg:50">
      <img class="w-[min(50vh,43vw)] lg:w-105" src="@/assets/images/oops.png" alt="oops">
      <section class="flex-col">
        <h1 class="text-16 c-#fff c-op-90 font-[Bebas_Neue] lg:text-24">
          OOPS!
        </h1>
        <p class="text-8 c-#fff c-op-90 font-[OSP-DIN]">
          FAILURE CODE: <span class="c-green-5 font-[OSP-DIN]">{{ error.statusCode }}</span>
        </p>

        <p class="mt-5 text-4 c-#fff c-op-50 lh-6 lg:mt-10">
          Unable to obtain web page content normally,<br>
          please refresh and try again. If the retry fails, please check the network.
        </p>

        <div class="mt-10 flex gap-4 lg:mt-20 md:gap-6">
          <div class="ld-button h-11 px-6 ld-button-green" @click="tapRefreshPage()">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1648_4044)">
                <path d="M7.99139 1.5C6.33044 1.50194 4.6701 2.13654 3.40283 3.40381C0.864427 5.94221 0.864427 10.0578 3.40283 12.5962C5.94124 15.1346 10.0568 15.1346 12.5952 12.5962C15.1336 10.0578 15.1336 5.94221 12.5952 3.40381L10.8275 5.17157" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1648_4044">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span class="mis-2 text-3.5">Refresh</span>
          </div>

          <div class="ld-button h-11 px-6 c-green-5 ld-button-black">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 9H3L5 7" stroke="#CCFF00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span class="mis-2 text-3.5" @click="clearError({ redirect: '/' })">Go Back</span>
          </div>
        </div>
      </section>
    </div>
  </ClientOnly>
</template>
