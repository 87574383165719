export const locales = [
  {
    code: 'en_US',
    androidLang: 'en-US',
    name: 'English',
  },
  {
    code: 'zh_CN',
    androidLang: 'zh-Hans-CN',
    name: '简体中文',
  },
  {
    code: 'zh_TW',
    androidLang: 'zh-Hant-TW',
    name: '繁體中文',
  },
  {
    code: 'ko_KR',
    androidLang: 'ko-KR',
    name: '한국어',
  },
  {
    code: 'vi_VN',
    androidLang: 'vi-VN',
    name: 'Tiếng Việt',
  },
  {
    code: 'th_TH',
    androidLang: 'th-TH',
    name: 'ไทย',
  },
  {
    code: 'ja_JP',
    androidLang: 'ja-JP',
    name: '日本語',
  },
  {
    code: 'ru_RU',
    androidLang: 'ru-RU',
    name: 'Русский',
  },
  {
    code: 'pt_PT',
    androidLang: 'pt-PT',
    name: 'Português',
  },
  {
    code: 'es_ES',
    androidLang: 'es-ES',
    name: 'Español',
  },
  // {
  //   code: 'ar_IL',
  //   androidLang: 'ar-IL',
  //   name: 'العربية',
  // },
]
